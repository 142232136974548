.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.line {
  border: 1px solid black;
  margin: 20px;
  width: 700px;
}

.http {
  color: blue;
}

/*index.css*/
.dropzone {
  text-align: center;
  padding: 20px;
  border: 3px blue dashed;
  width: 60%;
  margin: auto;
}

.btn {
  border: none;
  text-align: center;
  background-color: rgb(218, 216, 216);
  height: 50px;
  border-radius: 12px;
  color: black;
  font-weight: bold;
  transition-duration: 0.6s;
}

.btn:hover {
  background-color: blue;
  color: aliceblue;
}

.file-list {
  /* border: 3px dotted black; */
  display: flex !important;
  flex-wrap: wrap;
  width: auto;
  padding: 10px 20px;
  margin: 20px 30px;
  /* border: 3px dotted black; */
}

.file-list img {
  height: 100%;
  width: 100px;
  padding-right: 10px;
  object-fit: cover;
}

.Auth-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100vw;
  height: 100vh;
}

.Auth-form {
  width: 420px;
  box-shadow: rgb(0 0 0 / 16%) 1px 1px 10px;
  padding-top: 30px;
  padding-bottom: 20px;
  border-radius: 8px;
  background-color: white;
}

.Auth-form-content {
  padding-left: 12%;
  padding-right: 12%;
}

.Auth-form-title {
  text-align: center;
  margin-bottom: 1em;
  font-size: 24px;
  color: rgb(34, 34, 34);
  font-weight: 800;
}

label {
  font-size: 14px;
  font-weight: 600;
  color: rgb(34, 34, 34);
}

/* .logout {
  position: absolute;
  top: 10px;
  right: 10px;
} */

.logout {
  position: absolute;
  top: 10px;
  right: 10px;
  border-radius: 8px;
  background-color: #007bff; /* Blue background color */
  color: #ffffff; /* White text color */
  border: none;
  padding: 5px 10px;
  cursor: pointer;
  transition: background-color 0.3s ease; /* Smooth transition for background color */
}
 
.logout:hover {
  background-color: blue; /* Darker blue on hover */
}

